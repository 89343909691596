//! NOTE OBSOLETE
import React                  from 'react'
import Img                    from "gatsby-image"
import cx                     from 'utils/classnames'
import styles                 from './styles.module.scss'

const Hero = ({
    className,
    image,
    children,
    ...props
}) => {
    return (
        <div
            className={cx(
                styles.hero,
                className
            )}
            {...props}
        >
          <Img fluid={image} className={styles.heroImage}/>
          <div className={styles.content}>
            {children}
          </div>
        </div>
    )
}

export default Hero
