import React from "react"
import { map }              from 'lodash-es'
import {
    Container
}                           from 'react-bootstrap'
import RawHtml              from 'entrada-ui/RawHtml'
import Hero                 from 'components/Hero'
import InfoGroup            from 'components/InfoGroup'
import Seo                  from 'components/Seo'
import styles               from './styles.module.scss'

const InfoGroupCard = InfoGroup.CardWp

const TravelInfoTemplate = ({ pageContext: { data } }) => {
    const {
        siteMetadata,
        acf: {
            image,
            page_heading,
            overview,
            info_cards
        },
        yoast_meta
    } = data

    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            <Hero
                className={styles.hero}
                image={image.localFile.childImageSharp.fluid}
                >
                <h1 className={styles.heroTitle}>{page_heading}</h1>
            </Hero>
            <Container>
                <div className="text-center">
                    <RawHtml html={overview} />
                </div>

                <InfoGroup>
                    {map(info_cards, (card, idx) => (
                        <InfoGroupCard
                            key={idx}
                            {...card}
                        />
                    ))}
                </InfoGroup>

            </Container>
        </>

    )
}
export default TravelInfoTemplate

