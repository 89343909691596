import React                  from 'react'
import Img                    from "gatsby-image"
import {
    Col,
    Row
}                             from 'react-bootstrap'
import cx                     from 'utils/classnames'
import Link                   from 'components/Link'
import styles                 from './styles.module.scss'

const InfoGroup = ({ className, children, ...props }) => (
    <div className={cx(styles.container, className)} {...props}>
        <Row>
            {children}
        </Row>
    </div>
)

InfoGroup.Card = React.forwardRef(function InfoGroupCard(props, ref) {
    const {
        title,
        image,
        description,
        url,
    } = props

    return (
        <Col xs={12} sm={6} lg={4}>
            <div className={styles.root}>
                <Link to={url} className={styles.header}>
                    <Img
                        fluid={{
                            ...image,
                            aspectRatio: 4/3
                        }}
                    />
                </Link>
                <h4 className={styles.title}><Link to={url}>{title}</Link></h4>
                <p className={styles.summary}>{description}</p>
                <Link className={styles.more} to={url}>Read more</Link>
            </div>
        </Col>
    )
})

InfoGroup.CardWp = React.forwardRef(function InfoGroupCardWp(props, ref) {
    const {
        card_title,
        card_image,
        card_description,
        card_link,
        ...rest
    } = props
    
    return (
        <InfoGroup.Card
            {...rest}
            ref={ref}
            title={card_title} 
            image={card_image.localFile.childImageSharp.fluid}
            url={card_link.path}
            description={card_description}
        />
    )
})

export default InfoGroup
